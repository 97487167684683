import { Col, Row, Spin } from "antd";
import React from "react";

const Loader = () => {
  return (
    <Row align="middle" style={{ height: "100%" }}>
      <Col span={24} style={{ textAlign: "center" }}>
        <Spin size="large" />
      </Col>
    </Row>
  );
};

export default Loader;
