import React, { useState } from "react";
import {
  Button,
  Breadcrumb,
  Typography,
  Space,
  Input,
  Form,
  Divider,
  Tabs,
  Row,
  Col,
  Select,
} from "antd";
import { useTranslation } from "react-i18next";
import {
  SaveFilled,
  ReloadOutlined,
  ArrowLeftOutlined,
  CloseOutlined,
  BankOutlined,
  GlobalOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { useNavigate, NavLink } from "react-router-dom";
import { useNotification } from "../../../../components/Notifier";
import { useCreateCompanyMutation } from "../../../../store/slices/companySlice";

const { Title } = Typography;
const { Option } = Select;

const AdminCompaniesAdd = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { openMessage } = useNotification();
  const [createCompanyForm] = Form.useForm();
  const [createCompany, { isLoading: createCompanyIsLoading }] =
    useCreateCompanyMutation();
  const [name, setName] = useState("");
  const [domain, setDomain] = useState("");
  const [companyStatus, setCompanyStatus] = useState("ACTIVE");

  const onCreateCompanyFinish = async (e) => {
    try {
      await createCompany({
        name,
        domain,
        status: companyStatus,
      }).unwrap();
      openMessage("success", t("M_COMPANY_CREATED"));
      navigate("/admin/companies");
    } catch (err) {
      openMessage(
        "error",
        t(err?.data?.message || err.error || "S_UPDATE_FAILED")
      );
    }
  };

  const onCreateCompanyFinishFailed = (e) => {};

  // Function to reload the page
  const handleReload = () => {
    window.location.reload();
  };

  // Function to navigate back to the companies page
  const handleClose = () => {
    navigate("/admin/companies");
  };

  return (
    <div className="admin-page">
      <Row align="middle">
        <Col span={24}>
          <Breadcrumb style={{ marginBottom: "16px" }}>
            <Breadcrumb.Item>
              <NavLink to="/admin/companies">{t("S_COMPANIES")}</NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{t("S_ADD_COMPANY")}</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row align="middle">
        <Col span={12}>
          <Title level={4}>
            <NavLink
              to="/admin/companies"
              style={{
                marginRight: "8px",
                color: "#000000",
              }}
            >
              <ArrowLeftOutlined />
            </NavLink>
            {t("S_ADD_COMPANY")}
          </Title>
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <div className="add-button-wrapper">
            <Space>
              <Button
                type="primary"
                icon={<SaveFilled />}
                onClick={() => createCompanyForm.submit()}
                loading={createCompanyIsLoading}
              >
                {t("S_SAVE")}
              </Button>
              <Button icon={<ReloadOutlined />} onClick={handleReload}></Button>
              <Button icon={<CloseOutlined />} onClick={handleClose}></Button>
            </Space>
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: "16px" }}>
        <Col span={24}>
          <Tabs
            type="card"
            tabPosition="top"
            animated
            items={[
              {
                label: t("S_COMPANY_DETAILS"),
                key: "details",
                children: (
                  <div className="form-area">
                    <Divider orientation="left" orientationMargin="0">
                      {t("S_COMPANY_DETAILS")}
                    </Divider>
                    <Form
                      disabled={createCompanyIsLoading}
                      form={createCompanyForm}
                      onFinish={onCreateCompanyFinish}
                      onFinishFailed={onCreateCompanyFinishFailed}
                      layout="vertical"
                    >
                      <Form.Item
                        label={t("S_COMPANY_NAME")}
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: t("M_COMPANY_NAME_REQUIRED"),
                          },
                        ]}
                      >
                        <Input
                          placeholder={t("S_COMPANY_NAME")}
                          prefix={<BankOutlined />}
                          onChange={(e) => setName(e.target.value)}
                          value={name}
                        />
                      </Form.Item>
                      <Form.Item
                        label={t("S_DOMAIN")}
                        name="domain"
                        rules={[
                          {
                            required: true,
                            message: t("M_DOMAIN_REQUIRED"),
                          },
                          {
                            pattern: /^[a-z]+$/,
                            message: t("M_DOMAIN_LOWERCASE_ALPHA_ONLY"), // Custom error for alpha lowercase
                          },
                          {
                            min: 3,
                            max: 6,
                            message: t("M_DOMAIN_LENGTH_ERROR"), // Custom error for length
                          },
                        ]}
                      >
                        <Input
                          placeholder={t("S_COMPANY_DOMAIN")}
                          prefix={<GlobalOutlined />}
                          onChange={(e) => setDomain(e.target.value)}
                          value={domain}
                        />
                      </Form.Item>
                      <Form.Item
                        label={t("S_STATUS")}
                        name="status"
                        initialValue={companyStatus}
                        rules={[
                          {
                            required: true,
                            message: t("M_STATUS_REQUIRED"),
                          },
                        ]}
                      >
                        <Select
                          onChange={(value) => setCompanyStatus(value)}
                          value={companyStatus}
                          prefixIcon={<CheckCircleOutlined />}
                        >
                          <Option value="ACTIVE">{t("ACTIVE")}</Option>
                          <Option value="DEACTIVE">{t("DEACTIVE")}</Option>
                        </Select>
                      </Form.Item>
                    </Form>
                  </div>
                ),
              },
            ]}
          />
        </Col>
      </Row>
    </div>
  );
};

export default AdminCompaniesAdd;
