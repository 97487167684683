import React, { useState, useEffect } from "react";
import { ConfigProvider } from "antd";
import enUS from "antd/es/locale/en_US"; // English Locale
import trTR from "antd/es/locale/tr_TR"; // Turkish Locale
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { NotificationProvider } from "./components/Notifier";
/* LAYOUTS */
import AuthLayout from "./layouts/AuthLayout";
import MainLayout from "./layouts/MainLayout";
import AdminLayout from "./layouts/AdminLayout";

/* VIEWS */
/** PUBLIC **/
import Error from "./views/public/Error";
import Login from "./views/public/Login";
import Register from "./views/public/Register";
import RegisterSuccess from "./views/public/RegisterSuccess";
import RegisterComplete from "./views/public/RegisterComplete";
import ForgotPassword from "./views/public/ForgotPassword";
import ForgotPasswordSuccess from "./views/public/ForgotPasswordSuccess";
import ChangePassword from "./views/public/ChangePassword";
import ChangePasswordSuccess from "./views/public/ChangePasswordSuccess";
import Logout from "./views/public/Logout";

/* ADMIN */
import AdminUsers from "./views/protected/admin/adminUsers/AdminUsers";
import AdminUsersAdd from "./views/protected/admin/adminUsers/AdminUsersAdd";
import AdminUsersEdit from "./views/protected/admin/adminUsers/AdminUsersEdit";
import AdminCompanies from "./views/protected/admin/adminCompanies/AdminCompanies";
import AdminCompaniesAdd from "./views/protected/admin/adminCompanies/AdminCompaniesAdd";
import AdminCompaniesEdit from "./views/protected/admin/adminCompanies/AdminCompaniesEdit";

/* APP */
import Dashboard from "./views/protected/app/Dashboard";
import CompanyAdminUsers from "./views/protected/companyadmin/companyAdminUsers/companyAdminUsers";
import CompanyAdminUsersAdd from "./views/protected/companyadmin/companyAdminUsers/companyAdminUsersAdd";
import CompanyAdminUsersEdit from "./views/protected/companyadmin/companyAdminUsers/companyAdminUsersEdit";

const App = () => {
  const { userInfo } = useSelector((state) => state.authInfo);
  const [locale, setLocale] = useState(enUS); // Default to English

  useEffect(() => {
    // Check localStorage for language preference
    const languageInfo = localStorage.getItem("languageInfo");

    if (languageInfo) {
      const parsedLanguageInfo = JSON.parse(languageInfo); // Parse JSON
      const selectedLanguage = parsedLanguageInfo.language; // Get language (e.g., "EN", "TR")
      if (selectedLanguage === "TR") {
        setLocale(trTR); // Set Turkish locale if selected
      } else {
        setLocale(enUS); // Default to English
      }
    } else {
      setLocale(trTR);
    }
  }, []);
  return (
    <ConfigProvider
      locale={locale}
      theme={{
        token: {
          colorPrimary: "#1890ff",
        },
      }}
    >
      <NotificationProvider>
        <Router>
          <Routes>
            <Route element={<MainLayout />}>
              <Route path="/app/dashboard" element={<Dashboard />} />
            </Route>
            <Route
              element={
                !userInfo ? (
                  <AuthLayout />
                ) : userInfo &&
                  userInfo.roles &&
                  userInfo.roles.some(
                    (role) => role === "APPLICATION_ADMIN"
                  ) ? (
                  <Navigate to={`/admin/users`} replace />
                ) : (
                  <Navigate to={`/app/dashboard`} replace />
                )
              }
            >
              <Route path="/" element={<Login />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/registersuccess" element={<RegisterSuccess />} />
              <Route
                path="/registercomplete/:registerToken"
                element={<RegisterComplete />}
              />
              <Route path="/forgotpassword" element={<ForgotPassword />} />
              <Route path="/fpsuccess" element={<ForgotPasswordSuccess />} />
              <Route
                path="/changepassword/:forgotPasswordToken"
                element={<ChangePassword />}
              />
              <Route path="/cpsuccess" element={<ChangePasswordSuccess />} />
            </Route>
            <Route
              element={
                userInfo &&
                userInfo.roles &&
                userInfo.roles.some((role) => role === "APPLICATION_ADMIN") ? (
                  <AdminLayout />
                ) : (
                  <Navigate to="/error" replace />
                )
              }
            >
              <Route path="/admin/users" element={<AdminUsers />} />
              <Route path="/admin/users/add" element={<AdminUsersAdd />} />
              <Route path="/admin/users/:uuid" element={<AdminUsersEdit />} />
              <Route path="/admin/companies" element={<AdminCompanies />} />
              <Route
                path="/admin/companies/:uuid"
                element={<AdminCompaniesEdit />}
              />
              <Route
                path="/admin/companies/add"
                element={<AdminCompaniesAdd />}
              />
            </Route>
            <Route
              element={
                userInfo &&
                userInfo.roles &&
                userInfo.roles.some((role) => role === "COMPANY_ADMIN") ? (
                  <AdminLayout />
                ) : (
                  <Navigate to="/error" replace />
                )
              }
            >
              <Route
                path="/company"
                element={<Navigate to="/company/admin/users" />}
              />
              <Route
                path="/company/admin"
                element={<Navigate to="/company/admin/users" />}
              />
              <Route
                path="/company/admin/users"
                element={<CompanyAdminUsers />}
              />
              <Route
                path="/company/admin/users/add"
                element={<CompanyAdminUsersAdd />}
              />
              <Route
                path="/company/admin/users/:uuid"
                element={<CompanyAdminUsersEdit />}
              />
            </Route>
            <Route path="/logout" element={<Logout />} />
            <Route path="/error" element={<Error />} />
            <Route path="*" element={<Error />} />
          </Routes>
        </Router>
      </NotificationProvider>
    </ConfigProvider>
  );
};

export default App;
