import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLogoutMutation } from "../../store/slices/authSlice";
import { useNavigate } from "react-router-dom";
import { logout } from "../../store/slices/authInfoSlice";
const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [logoutApiCall] = useLogoutMutation();

  useEffect(() => {
    const fetchLogout = async () => {
      try {
        await logoutApiCall().unwrap();
      } catch (error) {
        navigate("/error");
      }
    };

    fetchLogout();
    dispatch(logout());
    navigate("/login");
  }, [navigate, logoutApiCall, dispatch]);
};

export default Logout;
