import React from "react";
import { Layout, Select } from "antd";
import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import Flag from "react-world-flags";

import En from "../resources/translations/EN";
import Tr from "../resources/translations/TR";

const { Footer } = Layout;
const { Option } = Select;

const translationEN = En;
const translationTR = Tr;
const languageInfo = localStorage.getItem("languageInfo");
const selectedLanguage = languageInfo
  ? JSON.parse(languageInfo).language
  : "TR";
i18n.use(initReactI18next).init({
  resources: {
    TR: { translation: translationTR },
    EN: { translation: translationEN },
  },
  lng: selectedLanguage,
  fallbackLng: "TR",
  interpolation: { escapeValue: false },
});

const handleChange = (value) => {
  i18n.changeLanguage(value);
  localStorage.setItem("languageInfo", JSON.stringify({ language: value }));
};

const LangFooter = () => {
  const { t } = useTranslation();
  return (
    <Footer style={{ textAlign: "center" }}>
      <Select
        defaultValue={selectedLanguage}
        className="language-selector"
        onChange={handleChange}
      >
        <Option value="TR">
          <Flag code="TR" height="10" />
          {t("S_TURKISH")}
        </Option>
        <Option value="EN">
          <Flag code="GB" height="10" />
          {t("S_ENGLISH")}
        </Option>
      </Select>
    </Footer>
  );
};

export default LangFooter;
