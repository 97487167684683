import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, NavLink } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Checkbox,
  Typography,
  Image,
  Space,
  Divider,
  message,
} from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import logoV64 from "../../resources/images/logo-64.png";

import { useLoginMutation } from "../../store/slices/authSlice";

import { setAuthInfo } from "../../store/slices/authInfoSlice";

const { Paragraph } = Typography;

const Login = () => {
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [login, { isLoading }] = useLoginMutation();

  const onFinish = async (e) => {
    try {
      const res = await login({ email, password }).unwrap();
      dispatch(setAuthInfo({ ...res })).then(navigate("/app"));
    } catch (err) {
      messageApi.open({
        type: "error",
        content: err?.data?.message || err.error,
      });
    }
  };

  const onFinishFailed = (e) => {};

  return (
    <div style={{ margin: 0, padding: 0 }}>
      {contextHolder}
      <div style={{ margin: "auto" }}>
        <Row
          type="flex"
          justify="center"
          align="middle"
          style={{ minHeight: "calc(100vh - 160px)" }}
        >
          <Col>
            <Row justify="center">
              <Image width={64} src={logoV64} preview={false} />
            </Row>
            <Row className="auth-container-body" style={{ marginTop: 20 }}>
              <Form
                style={{ minWidth: "210px" }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                disabled={isLoading}
              >
                <Form.Item justify="center" align="middle">
                  <Paragraph style={{ textAlign: "center" }}>
                    {t("S_LOGIN_TEXT")}
                  </Paragraph>
                </Form.Item>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: t("EM_EMAIL_REQUIRED"),
                    },
                    {
                      type: "email",
                      message: t("EM_EMAIL_INVALID"),
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={t("S_EMAIL")}
                    prefix={<UserOutlined />}
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: t("EM_PASSWORD_REQUIRED"),
                    },
                  ]}
                >
                  <Input.Password
                    size="large"
                    placeholder={t("S_PASSWORD")}
                    prefix={<LockOutlined />}
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                  />
                </Form.Item>
                <Form.Item wrapperCol={{ span: 24 }}>
                  <Space style={{ width: "100%" }} className="login-space">
                    <Checkbox>{t("S_REMEMBER_ME")}</Checkbox>

                    <Link
                      to="/forgotpassword"
                      style={{ float: "right", flex: "auto" }}
                    >
                      <Button type="link" style={{ padding: 0 }}>
                        {t("S_FORGOT_PASSWORD")}
                      </Button>
                    </Link>
                  </Space>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    loading={isLoading}
                  >
                    {t("S_LOGIN")}
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Divider />
                </Form.Item>
                <Form.Item style={{ textAlign: "center" }}>
                  <NavLink to="/register">{t("S_NOT_REGISTERED")}</NavLink>
                </Form.Item>
              </Form>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Login;
