import React, { useEffect, useMemo } from "react";
import { Button, Typography, Space, Tag, Breadcrumb, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import ProTable from "@ant-design/pro-table";
import { useLazyGetAuthsQuery } from "../../../../store/slices/adminAuthSlice";
import { useGetCompaniesQuery } from "../../../../store/slices/companySlice";
import Message from "../../../../components/Message";
import Loader from "../../../../components/Loader";

const { Title } = Typography;

const AdminUsers = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Fetch auth and company data
  const [
    triggerGetAuths,
    {
      isLoading: getAuthsIsLoading,
      isError: getAuthsIsError,
      error: getAuthsError,
      data: getAuthsData,
    },
  ] = useLazyGetAuthsQuery();
  const {
    data: companiesData,
    isLoading: getCompaniesIsLoading,
    isError: getCompaniesIsError,
    error: getCompaniesError,
  } = useGetCompaniesQuery();

  // Fetch data on page load
  useEffect(() => {
    triggerGetAuths({
      page: 1,
      pageSize: 10,
      sortField: "createdAt",
      sortOrder: "ascend",
    });
  }, [triggerGetAuths]);

  // Dynamically map the company data to valueEnum format
  const companyValueEnum = useMemo(() => {
    const result = {};
    companiesData?.companies.forEach((company) => {
      result[company.name] = { text: company.name }; // Use UUID instead of _id
    });
    return result;
  }, [companiesData]);

  // Define the columns for ProTable
  const columns = [
    {
      title: "UUID",
      dataIndex: "uuid", // Use UUID instead of _id
      key: "uuid",
      sorter: true,
      search: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: true,
      search: true,
    },
    {
      title: "Name",
      dataIndex: ["name"],
      key: "name",
      sorter: true,
      search: true,
    },
    {
      title: "Surname",
      dataIndex: ["surname"],
      key: "surname",
      sorter: true,
      search: true,
    },
    {
      title: "Phone",
      dataIndex: ["phone"],
      key: "phone",
      sorter: true,
      search: true,
    },
    {
      title: "Company",
      dataIndex: ["company", "name"], // Fetch company UUID
      key: "company",
      sorter: true,
      valueEnum: companyValueEnum, // Dynamically map company UUID to name
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: true,
      valueEnum: {
        ACTIVE: { text: "ACTIVE" },
        DEACTIVE: { text: "DEACTIVE" },
      },
      render: (_, record) => (
        <Tag color={record.status === "ACTIVE" ? "green" : "red"}>
          {t(record.status)}
        </Tag>
      ),
    },
    {
      title: "Roles",
      dataIndex: "roles",
      key: "roles",
      valueEnum: {
        APPLICATION_ADMIN: { text: "APPLICATION_ADMIN" },
        COMPANY_ADMIN: { text: "COMPANY_ADMIN" },
        USER: { text: "USER" },
      },
      render: (_, record) => (
        <Space direction="vertical">
          {record.roles.map((role) => (
            <Tag color="blue" key={role} style={{ marginBottom: "4px" }}>
              {t(`${role.toUpperCase()}`)}
            </Tag>
          ))}
        </Space>
      ),
    },
    {
      title: t("S_ACTIONS"),
      key: "actions",
      fixed: "right",
      width: 110,
      search: false,
      render: (_, record) => (
        <Button
          icon={<EditOutlined />}
          type="primary"
          onClick={() => navigate(`/admin/users/${record.uuid}`)} // Use UUID in navigation
        ></Button>
      ),
    },
  ];

  // ProTable request method to handle sorting, filtering, and pagination
  const handleRequest = async (params, sorter, filter) => {
    const { current: page, pageSize } = params;

    // Trigger the lazy query with the appropriate params
    const response = await triggerGetAuths({
      page,
      pageSize,
      sortField: Object.keys(sorter)[0] || "createdAt", // Sorting field
      sortOrder: sorter[Object.keys(sorter)[0]] || "ascend", // Sorting order
      uuid: params.uuid,
      name: params.name, // Query filters
      email: params.email,
      surname: params.surname,
      phone: params.phone,
      companyName: params.company,
      status: params.status,
      roles: params.roles,
    });

    if (response.data) {
      // Map the response to ProTable's expected data format
      return {
        data: response.data.auths, // Data from the backend
        success: true, // Indicating request success
        total: response.data.pagination.total, // Total number of records
      };
    }

    // Handle error or failure scenario
    return {
      success: false,
    };
  };

  // Reload from scratch with default parameters
  const handleReload = async () => {
    triggerGetAuths({
      page: 1, // Default page
      pageSize: 10, // Default page size
      sortField: "createdAt", // Default sort field
      sortOrder: "ascend", // Default sort order
    });
  };

  return getAuthsIsLoading || getCompaniesIsLoading ? (
    <Loader />
  ) : getAuthsIsError || getCompaniesIsError ? (
    <Message
      variant="error"
      content={t(
        getAuthsError?.data?.message ||
          getAuthsError.error ||
          getCompaniesError?.data?.message ||
          getCompaniesError.error
      )}
    />
  ) : (
    <div className="admin-page">
      <Row align="middle">
        <Col span={24}>
          <Breadcrumb style={{ marginBottom: "16px" }}>
            <Breadcrumb.Item>{t("S_USERS")}</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row align="middle">
        <Col span={12}>
          <Title level={4}>{t("S_ADMIN_USERS")}</Title>
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <Button
            key="add"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => navigate("/admin/users/add")}
          >
            {t("S_ADD_USER")}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div style={{ marginTop: "20px" }}>
            {/* ProTable for user data */}
            <ProTable
              columns={columns}
              rowKey="uuid" // Use UUID as row key
              dataSource={getAuthsData?.auths || []}
              pagination={{
                pageSize: 10, // Default page size
              }}
              loading={getAuthsIsLoading} // Pass the loading state to ProTable
              request={handleRequest} // Use request method for data fetching
              search={{
                labelWidth: "auto", // Control the label width for search form
              }}
              columnsState={{
                persistenceKey: "auths-table-configuration",
                persistenceType: "localStorage",
                defaultValue: {
                  option: { fixed: "right", disable: true },
                },
              }}
              scroll={{ x: "max-content" }}
              options={{
                reload: handleReload, // Reload data from scratch and reset form
              }}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AdminUsers;
