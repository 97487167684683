import { apiSlice } from "./apiSlice";
import { ADMIN_AUTH_URL } from "../constants/constants";

export const adminAuthSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createAuth: builder.mutation({
      query: (data) => ({
        url: `${ADMIN_AUTH_URL}/auth`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Auth"],
    }),
    getAuths: builder.query({
      query: (params) => ({
        url: `${ADMIN_AUTH_URL}`,
        method: "POST",
        body: params,
      }),
      providesTags: ["Auth"],
      keepUnusedDataFor: 5,
    }),
    getAuthByUuid: builder.query({
      query: (uuid) => ({
        url: `${ADMIN_AUTH_URL}/${uuid}`,
        method: "GET",
      }),
      providesTags: ["Auth"],
      keepUnusedDataFor: 5, // Cache for 5 seconds
    }),
    updateAuth: builder.mutation({
      query: (data) => {
        return {
          url: `${ADMIN_AUTH_URL}/${data.uuid}`,
          method: "PATCH",
          body: data,
        };
      },
      invalidatesTags: ["Auth", "Company"],
    }),
  }),
});

export const {
  useCreateAuthMutation,
  useGetAuthsQuery,
  useGetAuthByUuidQuery,
  useLazyGetAuthsQuery,
  useUpdateAuthMutation,
} = adminAuthSlice;
