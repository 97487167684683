import React, { useEffect } from "react";
import {
  Button,
  Breadcrumb,
  Typography,
  Space,
  Input,
  Select,
  Form,
  Divider,
  Tabs,
  Row,
  Col,
  Descriptions,
} from "antd";
import { useTranslation } from "react-i18next";
import {
  SaveFilled,
  ReloadOutlined,
  ArrowLeftOutlined,
  CloseOutlined,
  MailOutlined,
  UserOutlined,
  BankOutlined,
  PhoneOutlined,
  CheckCircleOutlined,
  TeamOutlined,
  LockOutlined,
} from "@ant-design/icons";
import { useNavigate, NavLink, useParams } from "react-router-dom";
import Message from "../../../../components/Message";
import Loader from "../../../../components/Loader";
import { useNotification } from "../../../../components/Notifier";
import {
  useGetAuthByUuidQuery,
  useUpdateAuthMutation,
} from "../../../../store/slices/adminAuthSlice";
import { useForgotPasswordMutation } from "../../../../store/slices/authSlice";
import { useGetCompaniesQuery } from "../../../../store/slices/companySlice";

const { Title } = Typography;
const { Option } = Select;

const AdminUsersEdit = () => {
  const { uuid } = useParams(); // Get the UUID from the URL
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { openMessage } = useNotification();
  const [updateAuthForm] = Form.useForm();
  // Fetch auth details using the UUID
  const {
    data: authData,
    isLoading: getAuthIsLoading,
    isError: getAuthIsError,
    error: getAuthError,
  } = useGetAuthByUuidQuery(uuid);

  const {
    data: companiesData,
    isLoading: getCompaniesIsLoading,
    isError: getCompaniesIsError,
    error: getCompaniesError,
  } = useGetCompaniesQuery();

  const [updateAuth, { isLoading: updateAuthIsLoading }] =
    useUpdateAuthMutation();

  const [forgotPassword, { isLoading: resetPasswordIsLoading }] =
    useForgotPasswordMutation();

  useEffect(() => {
    if (authData) {
      // Prefill form with data from API
      updateAuthForm.setFieldsValue({
        email: authData.auth.email,
        name: authData.auth.name,
        surname: authData.auth.surname,
        company: authData.auth.company,
        phone: authData.auth.phone,
        status: authData.auth.status,
        roles: authData.auth.roles,
      });
    }
  }, [authData, updateAuthForm]);

  const onUpdateAuthFinish = async (values) => {
    try {
      await updateAuth({
        uuid,
        ...values,
      }).unwrap();
      openMessage("success", t("M_USER_UPDATED"));
      navigate("/admin/users");
    } catch (err) {
      console.log(err);
      openMessage(
        "error",
        t(err?.data?.message || err.error || "S_UPDATE_FAILED")
      );
    }
  };

  const onUpdateAuthFinishFailed = async () => {};

  const handleResetPassword = async () => {
    try {
      await forgotPassword({ email: authData.auth.email }).unwrap(); // Call forgot password with the user’s email
      openMessage("success", t("M_RESET_PASSWORD_EMAIL_SENT")); // Show success message
    } catch (err) {
      openMessage(
        "error",
        t(err?.data?.message || err.error || "S_RESET_PASSWORD_FAILED")
      );
    }
  };

  // Function to reload the page
  const handleReload = () => {
    window.location.reload();
  };

  // Function to navigate back to the users page
  const handleClose = () => {
    navigate("/admin/users");
  };

  return getCompaniesIsLoading || getAuthIsLoading ? (
    <Loader />
  ) : getCompaniesIsError || getAuthIsError ? (
    <Message
      variant="error"
      content={t(
        getCompaniesError?.data?.message ||
          getCompaniesError?.error ||
          getAuthError?.data?.message ||
          getAuthError?.error
      )}
    />
  ) : (
    <div className="admin-page">
      <Row align="middle">
        <Col span={24}>
          <Breadcrumb style={{ marginBottom: "16px" }}>
            <Breadcrumb.Item>
              <NavLink to="/admin/users">{t("S_USERS")}</NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{t("S_ADD_USER")}</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row align="middle">
        <Col span={12}>
          <Title level={4}>
            <NavLink
              to="/admin/users"
              style={{
                marginRight: "8px",
                color: "#000000",
              }}
            >
              <ArrowLeftOutlined />
            </NavLink>
            {t("S_USER_DETAIL")}
          </Title>
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <div className="add-button-wrapper">
            <Space>
              <Button
                type="primary"
                icon={<SaveFilled />}
                onClick={() => updateAuthForm.submit()}
                loading={updateAuthIsLoading}
              >
                {t("S_SAVE")}
              </Button>
              <Button
                icon={<LockOutlined />}
                onClick={handleResetPassword}
                loading={resetPasswordIsLoading}
              >
                {t("S_RESET_PASSWORD")}
              </Button>
              <Button icon={<ReloadOutlined />} onClick={handleReload}></Button>
              <Button icon={<CloseOutlined />} onClick={handleClose}></Button>
            </Space>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ marginTop: "16px" }}>
          <Descriptions
            bordered
            size="small"
            items={[
              {
                key: "uuid",
                label: t("S_ID"),
                children: authData.auth.uuid,
              },
              {
                key: "createdDate",
                label: t("S_CREATED_DATE"),
                children: authData.auth.createdAt,
              },
              {
                key: "createdBy",
                label: t("S_CREATED_BY"),
                children:
                  authData.auth.createdBy?.name +
                  " " +
                  authData.auth.createdBy?.surname,
              },
              {
                key: "updatedDate",
                label: t("S_UPDATED_DATE"),
                children: authData.auth.updatedAt,
              },
              {
                key: "updatedBy",
                label: t("S_UPDATED_BY"),
                children:
                  authData.auth.updatedBy?.name +
                  " " +
                  authData.auth.updatedBy?.surname,
              },
              {
                key: "status",
                label: t("S_STATUS"),
                children: t(authData.auth.status),
              },
            ]}
          ></Descriptions>
        </Col>
      </Row>
      <Row style={{ marginTop: "16px" }}>
        <Col span={24}>
          <Tabs
            type="card"
            tabPosition="top"
            animated
            items={[
              {
                label: t("S_USER_DETAILS"),
                key: "details",
                children: (
                  <div className="form-area">
                    <Form
                      disabled={updateAuthIsLoading}
                      form={updateAuthForm}
                      onFinish={onUpdateAuthFinish}
                      onFinishFailed={onUpdateAuthFinishFailed}
                      layout="vertical"
                    >
                      <Row gutter={[32, 16]}>
                        <Col xs={24} lg={16}>
                          <Divider orientation="left" orientationMargin="0">
                            {t("S_USER_DETAILS")}
                          </Divider>

                          <Form.Item
                            label={t("S_EMAIL")}
                            name="email"
                            rules={[
                              {
                                required: true,
                                message: t("EM_EMAIL_REQUIRED"),
                              },
                              {
                                type: "email",
                                message: t("EM_EMAIL_INVALID"),
                              },
                            ]}
                          >
                            <Input
                              placeholder={t("S_EMAIL")}
                              prefix={<MailOutlined />}
                            />
                          </Form.Item>
                          <Form.Item
                            label={t("S_NAME")}
                            name="name"
                            rules={[
                              {
                                required: true,
                                message: t("EM_NAME_REQUIRED"),
                              },
                            ]}
                          >
                            <Input
                              placeholder={t("S_NAME")}
                              prefix={<UserOutlined />}
                            />
                          </Form.Item>
                          <Form.Item
                            label={t("S_SURNAME")}
                            name="surname"
                            rules={[
                              {
                                required: true,
                                message: t("EM_SURNAME_REQUIRED"),
                              },
                            ]}
                          >
                            <Input
                              placeholder={t("S_SURNAME")}
                              prefix={<UserOutlined />}
                            />
                          </Form.Item>
                          <Form.Item
                            label={t("S_PHONE")}
                            name="phone"
                            rules={[
                              {
                                required: true,
                                message: t("EM_PHONE_REQUIRED"),
                              },
                              {
                                pattern: /^[0-9]+$/,
                                message: t("EM_PHONE_NUMERIC"),
                              },
                            ]}
                          >
                            <Input
                              placeholder={t("S_PHONE")}
                              prefix={<PhoneOutlined />}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} lg={8}>
                          <Divider orientation="left" orientationMargin="0">
                            {t("S_RECORD_DETAILS")}
                          </Divider>
                          <Form.Item
                            label={t("S_STATUS")}
                            name="status"
                            rules={[
                              {
                                required: true,
                                message: t("EM_STATUS_REQUIRED"),
                              },
                            ]}
                          >
                            <Select
                              prefixIcon={<CheckCircleOutlined />} // Added prefix icon for status
                            >
                              <Option value="ACTIVE">{t("ACTIVE")}</Option>
                              <Option value="DEACTIVE">{t("DEACTIVE")}</Option>
                            </Select>
                          </Form.Item>
                          <Divider orientation="left" orientationMargin="0">
                            {t("S_COMPANY_DETAILS")}
                          </Divider>
                          <Form.Item
                            label={t("S_COMPANY")}
                            name="company"
                            rules={[
                              {
                                required: true,
                                message: t("EM_COMPANY_REQUIRED"),
                              },
                            ]}
                          >
                            <Select
                              placeholder={t("S_SELECT_COMPANY")}
                              loading={getCompaniesIsLoading}
                              prefixIcon={<BankOutlined />}
                            >
                              {companiesData?.companies.map((company) => (
                                <Option key={company._id} value={company._id}>
                                  {company.name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                          <Divider orientation="left" orientationMargin="0">
                            {t("S_ROLE_DETAILS")}
                          </Divider>
                          <Form.Item
                            label={t("S_ROLES")}
                            name="roles"
                            rules={[
                              {
                                required: true,
                                message: t("EM_ROLES_REQUIRED"),
                              },
                            ]}
                          >
                            <Select
                              mode="multiple"
                              placeholder={t("S_SELECT_ROLES")}
                              prefixIcon={<TeamOutlined />} // Added prefix icon for roles
                            >
                              <Option value="APPLICATION_ADMIN">
                                {t("APPLICATION_ADMIN")}
                              </Option>
                              <Option value="COMPANY_ADMIN">
                                {t("COMPANY_ADMIN")}
                              </Option>
                              <Option value="COMPANY_OPERATOR">
                                {t("COMPANY_OPERATOR")}
                              </Option>
                              <Option value="USER">{t("USER")}</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                ),
              },
            ]}
          />
        </Col>
      </Row>
    </div>
  );
};

export default AdminUsersEdit;
