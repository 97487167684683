import { apiSlice } from "./apiSlice";
import { AUTH_URL } from "../constants/constants";

export const authSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: `${AUTH_URL}/login`,
        method: "POST",
        body: data,
      }),
    }),
    register: builder.mutation({
      query: (data) => ({
        url: `${AUTH_URL}/register`,
        method: "POST",
        body: data,
      }),
    }),
    registerComplete: builder.mutation({
      query: (registerToken) => ({
        url: `${AUTH_URL}/registercomplete/${registerToken}`,
        method: "POST",
        keepUnusedDataFor: 5,
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: `${AUTH_URL}/logout`,
        method: "POST",
      }),
    }),
    forgotPassword: builder.mutation({
      query: (data) => ({
        url: `${AUTH_URL}/forgotpassword`,
        method: "POST",
        body: data,
      }),
    }),
    changePassword: builder.mutation({
      query: (data) => {
        const { password, passwordconfirm, forgotPasswordToken } = data;
        return {
          url: `${AUTH_URL}/changepassword/${forgotPasswordToken}`,
          method: "POST",
          body: { password, passwordconfirm },
        };
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useRegisterMutation,
  useRegisterCompleteMutation,
  useForgotPasswordMutation,
  useChangePasswordMutation,
} = authSlice;
