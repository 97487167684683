import { Col, Row, Alert } from "antd";
import React from "react";

const Message = ({ variant, content }) => {
  return (
    <Row align="middle">
      <Col span={24} style={{ textAlign: "center" }}>
        <Alert message={content} type={variant} showIcon closable />
      </Col>
    </Row>
  );
};

export default Message;
