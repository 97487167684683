import React from "react";
import { useSelector } from "react-redux";
import { Row, Col, Space, Button, Layout, Dropdown } from "antd";
import { redirect, useNavigate } from "react-router-dom";
import {
  MenuOutlined,
  SettingOutlined,
  PoweroffOutlined,
  UserOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

const { Header } = Layout;

const MainHeader = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { userInfo } = useSelector((state) => state.authInfo);

  if (!userInfo) {
    navigate("/error");
  } else {
    return (
      <Header className="header">
        <div className="logo" />
        {!userInfo
          ? redirect("/login")
          : userInfo && (
              <Row justify="end">
                <Col
                  span={24}
                  xs={0}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  align="right"
                >
                  <Space
                    style={{ marginRight: "0px" }}
                    className="protected-header-space"
                  >
                    <Dropdown
                      menu={{
                        items: [
                          {
                            label: (
                              <Link to="/logout">
                                <Space>
                                  <PoweroffOutlined />
                                  {t("S_LOGOUT")}
                                </Space>
                              </Link>
                            ),
                            key: "logout",
                          },
                        ],
                      }}
                    >
                      <Space style={{ color: "#ffffff" }}>
                        <UserOutlined />
                        {userInfo.email}
                        <DownOutlined />
                      </Space>
                    </Dropdown>
                  </Space>
                </Col>
                <Col
                  span={24}
                  xs={24}
                  sm={0}
                  md={0}
                  lg={0}
                  xl={0}
                  align="right"
                >
                  <Dropdown
                    menu={{
                      items: [
                        {
                          label: (
                            <Link to="/usersettings">
                              <Space>
                                <SettingOutlined />
                                {t("S_USERSETTINGS")}
                              </Space>
                            </Link>
                          ),
                          key: "usersettings",
                        },
                        {
                          label: `${userInfo.email}`,
                          key: "profile",
                          children: [
                            {
                              label: (
                                <Link to="/logout">
                                  <Space>
                                    <PoweroffOutlined />
                                    {t("S_LOGOUT")}
                                  </Space>
                                </Link>
                              ),
                              key: "logout",
                            },
                          ],
                        },
                      ],
                    }}
                  >
                    <Button type="primary">
                      <MenuOutlined />
                    </Button>
                  </Dropdown>
                </Col>
              </Row>
            )}
      </Header>
    );
  }
};

export default MainHeader;
