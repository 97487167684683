import React, { useEffect } from "react";
import {
  Button,
  Breadcrumb,
  Typography,
  Space,
  Input,
  Form,
  Divider,
  Row,
  Col,
  Descriptions,
  Select,
} from "antd";
import { useTranslation } from "react-i18next";
import {
  SaveFilled,
  ReloadOutlined,
  ArrowLeftOutlined,
  CloseOutlined,
  BankOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { useNavigate, NavLink, useParams } from "react-router-dom";
import Message from "../../../../components/Message";
import Loader from "../../../../components/Loader";
import { useNotification } from "../../../../components/Notifier";
import {
  useGetCompanyByUuidQuery,
  useUpdateCompanyMutation,
} from "../../../../store/slices/companySlice";

const { Title } = Typography;

const AdminCompaniesEdit = () => {
  const { uuid } = useParams(); // Get the UUID from the URL
  console.log(uuid);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { openMessage } = useNotification();
  const [updateCompanyForm] = Form.useForm();

  // Fetch company details using the UUID
  const {
    data: companyData,
    isLoading: getCompanyIsLoading,
    isError: getCompanyIsError,
    error: getCompanyError,
  } = useGetCompanyByUuidQuery(uuid);

  const [updateCompany, { isLoading: updateCompanyIsLoading }] =
    useUpdateCompanyMutation();

  useEffect(() => {
    if (companyData) {
      console.log(companyData);
      // Prefill form with data from API
      updateCompanyForm.setFieldsValue({
        name: companyData.company.name,
        domain: companyData.company.domain,
        status: companyData.company.status,
      });
    }
  }, [companyData, updateCompanyForm]);

  const onUpdateCompanyFinish = async (values) => {
    try {
      await updateCompany({
        uuid,
        ...values,
      }).unwrap();
      openMessage("success", t("M_COMPANY_UPDATED"));
      navigate("/admin/companies");
    } catch (err) {
      console.log(err);
      openMessage(
        "error",
        t(err?.data?.message || err.error || "S_UPDATE_FAILED")
      );
    }
  };

  const onUpdateCompanyFinishFailed = () => {};

  // Function to reload the page
  const handleReload = () => {
    window.location.reload();
  };

  // Function to navigate back to the companies page
  const handleClose = () => {
    navigate("/admin/companies");
  };

  return getCompanyIsLoading ? (
    <Loader />
  ) : getCompanyIsError ? (
    <Message
      variant="error"
      content={t(getCompanyError?.data?.message || getCompanyError?.error)}
    />
  ) : (
    <div className="admin-page">
      <Row align="middle">
        <Col span={24}>
          <Breadcrumb style={{ marginBottom: "16px" }}>
            <Breadcrumb.Item>
              <NavLink to="/admin/companies">{t("S_COMPANIES")}</NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{t("S_EDIT_COMPANY")}</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row align="middle">
        <Col span={12}>
          <Title level={4}>
            <NavLink
              to="/admin/companies"
              style={{
                marginRight: "8px",
                color: "#000000",
              }}
            >
              <ArrowLeftOutlined />
            </NavLink>
            {t("S_COMPANY_DETAIL")}
          </Title>
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <div className="add-button-wrapper">
            <Space>
              <Button
                type="primary"
                icon={<SaveFilled />}
                onClick={() => updateCompanyForm.submit()}
                loading={updateCompanyIsLoading}
              >
                {t("S_SAVE")}
              </Button>
              <Button icon={<ReloadOutlined />} onClick={handleReload}></Button>
              <Button icon={<CloseOutlined />} onClick={handleClose}></Button>
            </Space>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ marginTop: "16px" }}>
          <Descriptions
            bordered
            size="small"
            items={[
              {
                key: "uuid",
                label: t("S_ID"),
                children: companyData.company.uuid,
              },
              {
                key: "createdDate",
                label: t("S_CREATED_DATE"),
                children: companyData.company.createdAt,
              },
              {
                key: "createdBy",
                label: t("S_CREATED_BY"),
                children:
                  companyData.company.createdBy?.name +
                  " " +
                  companyData.company.createdBy?.surname,
              },
              {
                key: "updatedDate",
                label: t("S_UPDATED_DATE"),
                children: companyData.company.updatedAt,
              },
              {
                key: "updatedBy",
                label: t("S_UPDATED_BY"),
                children:
                  companyData.company.updatedBy?.name +
                  " " +
                  companyData.company.updatedBy?.surname,
              },
              {
                key: "status",
                label: t("S_STATUS"),
                children: t(companyData.company.status),
              },
            ]}
          ></Descriptions>
        </Col>
      </Row>
      <Row style={{ marginTop: "16px" }}>
        <Col span={24}>
          <div className="form-area">
            <Form
              disabled={updateCompanyIsLoading}
              form={updateCompanyForm}
              onFinish={onUpdateCompanyFinish}
              onFinishFailed={onUpdateCompanyFinishFailed}
              layout="vertical"
            >
              <Row gutter={[32, 16]}>
                <Col xs={24} lg={16}>
                  <Divider orientation="left" orientationMargin="0">
                    {t("S_COMPANY_DETAILS")}
                  </Divider>
                  <Form.Item
                    label={t("S_NAME")}
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: t("EM_NAME_REQUIRED"),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("S_NAME")}
                      prefix={<BankOutlined />}
                    />
                  </Form.Item>
                  <Form.Item
                    label={t("S_DOMAIN")}
                    name="domain"
                    rules={[
                      {
                        required: true,
                        message: t("EM_DOMAIN_REQUIRED"),
                      },
                      {
                        min: 3,
                        max: 6,
                        pattern: /^[a-z]+$/,
                        message: t("EM_DOMAIN_RULES"),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("S_DOMAIN")}
                      prefix={<BankOutlined />}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={8}>
                  <Divider orientation="left" orientationMargin="0">
                    {t("S_RECORD_DETAILS")}
                  </Divider>
                  <Form.Item
                    label={t("S_STATUS")}
                    name="status"
                    rules={[
                      {
                        required: true,
                        message: t("EM_STATUS_REQUIRED"),
                      },
                    ]}
                  >
                    <Select prefixIcon={<CheckCircleOutlined />}>
                      <Select.Option value="ACTIVE">
                        {t("ACTIVE")}
                      </Select.Option>
                      <Select.Option value="INACTIVE">
                        {t("INACTIVE")}
                      </Select.Option>
                      <Select.Option value="TRIAL">{t("TRIAL")}</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AdminCompaniesEdit;
