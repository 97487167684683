import React, { useEffect } from "react";
import { Button, Typography, Tag, Breadcrumb, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import ProTable from "@ant-design/pro-table";
import { useLazyGetCompaniesWithUsersQuery } from "../../../../store/slices/companySlice";
import Message from "../../../../components/Message";
import Loader from "../../../../components/Loader";

const { Title } = Typography;

const AdminCompanies = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Fetch companies with user counts
  const [
    triggerGetCompaniesWithUsers,
    {
      isLoading: getCompaniesWithUsersIsLoading,
      isError: getCompaniesWithUsersIsError,
      error: getCompaniesWithUsersError,
      data: getCompaniesWithUsersData,
    },
  ] = useLazyGetCompaniesWithUsersQuery();

  // Fetch data on page load
  useEffect(() => {
    triggerGetCompaniesWithUsers({
      page: 1,
      pageSize: 10,
      sortField: "createdAt",
      sortOrder: "ascend",
    });
  }, [triggerGetCompaniesWithUsers]);

  // Define the columns for ProTable
  const columns = [
    {
      title: "UUID",
      dataIndex: "uuid", // Use UUID instead of _id
      key: "uuid",
      sorter: true, // Sortable
      search: true, // Filterable
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true, // Sortable
      search: true, // Filterable
    },
    {
      title: "Domain",
      dataIndex: "domain",
      key: "domain",
      sorter: true, // Sortable
      search: true, // Filterable
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: true, // Sortable
      search: true, // Filterable
      valueEnum: {
        TRIAL: { text: "TRIAL" },
        ACTIVE: { text: "ACTIVE" },
        DEACTIVE: { text: "DEACTIVE" },
      },
      render: (_, record) => (
        <Tag color={record.status === "ACTIVE" ? "green" : "red"}>
          {t(record.status)}
        </Tag>
      ),
    },
    {
      title: "Created By",
      dataIndex: "createdBy", // Auth's name + surname
      key: "createdBy",
      render: (_, record) => `${record.createdBy ? record.createdBy : ""}`, // Combine name and surname
    },
    {
      title: "Active Users",
      dataIndex: "activeUsers",
      key: "activeUsers",
    },
    {
      title: "Total Users",
      dataIndex: "totalUsers",
      key: "totalUsers",
    },
    {
      title: t("S_ACTIONS"),
      key: "actions",
      fixed: "right",
      width: 110,
      search: false,
      render: (_, record) => (
        <Button
          icon={<EditOutlined />}
          type="primary"
          onClick={() => navigate(`/admin/companies/${record.uuid}`)} // Use UUID in navigation
        ></Button>
      ),
    },
  ];

  // ProTable request method to handle sorting, filtering, and pagination
  const handleRequest = async (params, sorter, filter) => {
    const { current: page, pageSize } = params;

    // Trigger the lazy query with the appropriate params
    const response = await triggerGetCompaniesWithUsers({
      page,
      pageSize,
      sortField: Object.keys(sorter)[0] || "createdAt", // Sorting field
      sortOrder: sorter[Object.keys(sorter)[0]] || "ascend", // Sorting order
      uuid: params.uuid,
      name: params.name, // Query filters
      domain: params.domain,
      status: params.status,
    });

    if (response.data) {
      // Map the response to ProTable's expected data format
      return {
        data: response.data.companies, // Data from the backend
        success: true, // Indicating request success
        total: response.data.pagination.total, // Total number of records
      };
    }

    // Handle error or failure scenario
    return {
      success: false,
    };
  };

  // Reload from scratch with default parameters
  const handleReload = async () => {
    triggerGetCompaniesWithUsers({
      page: 1, // Default page
      pageSize: 10, // Default page size
      sortField: "createdAt", // Default sort field
      sortOrder: "ascend", // Default sort order
    });
  };

  return getCompaniesWithUsersIsLoading ? (
    <Loader />
  ) : getCompaniesWithUsersIsError ? (
    <Message
      variant="error"
      content={t(
        getCompaniesWithUsersError?.data?.message ||
          getCompaniesWithUsersError.error
      )}
    />
  ) : (
    <div className="admin-page">
      <Row align="middle">
        <Col span={24}>
          <Breadcrumb style={{ marginBottom: "16px" }}>
            <Breadcrumb.Item>{t("S_COMPANIES")}</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row align="middle">
        <Col span={12}>
          <Title level={4}>{t("S_ADMIN_COMPANIES")}</Title>
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <Button
            key="add"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => navigate("/admin/companies/add")}
          >
            {t("S_ADD_COMPANY")}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div style={{ marginTop: "20px" }}>
            {/* ProTable for company data */}
            <ProTable
              columns={columns}
              rowKey="uuid" // Use UUID as row key
              dataSource={getCompaniesWithUsersData?.companies || []}
              pagination={{
                pageSize: 10, // Default page size
              }}
              loading={getCompaniesWithUsersIsLoading} // Pass the loading state to ProTable
              request={handleRequest} // Use request method for data fetching
              search={{
                labelWidth: "auto", // Control the label width for search form
              }}
              columnsState={{
                persistenceKey: "companies-table-configuration",
                persistenceType: "localStorage",
                defaultValue: {
                  option: { fixed: "right", disable: true },
                },
              }}
              scroll={{ x: "max-content" }}
              options={{
                reload: handleReload, // Reload data from scratch and reset form
              }}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AdminCompanies;
