import { apiSlice } from "./apiSlice";
import { ADMIN_COMPANY_URL } from "../constants/constants";

export const companySlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Create a new company
    createCompany: builder.mutation({
      query: (data) => ({
        url: `${ADMIN_COMPANY_URL}/company`, // Adjusted to the correct route
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Company"],
    }),

    // Get companies with filter, sort, and pagination
    getCompanies: builder.query({
      query: (data) => ({
        url: `${ADMIN_COMPANY_URL}`,
        method: "POST",
        body: data, // Send filters, sort, pagination in the body
      }),
      providesTags: ["Company"],
    }),

    // Get a company by UUID
    getCompanyByUuid: builder.query({
      query: (companyUuid) => ({
        url: `${ADMIN_COMPANY_URL}/${companyUuid}`, // Adjusted to use the UUID
        method: "GET",
      }),
      providesTags: ["Company"],
    }),

    // Get companies with user counts (for admin overview)
    getCompaniesWithUsers: builder.query({
      query: (data) => ({
        url: `${ADMIN_COMPANY_URL}/wusers`, // Adjusted to the correct route for companies with user counts
        method: "POST",
        body: data, // Send filters, sort, pagination in the body
      }),
      providesTags: ["Company"],
    }),

    // Update a company by UUID
    updateCompany: builder.mutation({
      query: ({ uuid, ...data }) => ({
        url: `${ADMIN_COMPANY_URL}/${uuid}`, // Adjusted to use the UUID
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Company"],
    }),
  }),
});

export const {
  useCreateCompanyMutation,
  useGetCompaniesQuery,
  useGetCompanyByUuidQuery,
  useUpdateCompanyMutation,
  useGetCompaniesWithUsersQuery,
  useLazyGetCompaniesWithUsersQuery,
} = companySlice;
