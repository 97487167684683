import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Layout } from "antd";
import { Outlet, Navigate } from "react-router-dom";

/* COMPONENTS */
import MainHeader from "../components/MainHeader";
import MainSidebar from "../components/MainSidebar";
import MainFooter from "../components/MainFooter";
import LangFooter from "../components/LangFooter";
import { logout } from "../store/slices/authInfoSlice";
const { Content } = Layout;

const MainLayout = () => {
  const { userInfo } = useSelector((state) => state.authInfo);
  const dispatch = useDispatch();
  const expirationTime = localStorage.getItem("expirationTime");

  useEffect(() => {
    if (expirationTime) {
      const currentTime = new Date().getTime();

      if (currentTime > expirationTime) {
        dispatch(logout());
      }
    }
  }, [dispatch, expirationTime]);

  return userInfo ? (
    <Layout>
      <MainHeader />
      <Layout>
        <MainSidebar />
        <Layout
          style={{ padding: "24px 24px", minHeight: "calc(100vh - 64px)" }}
        >
          <Content>
            <Outlet />
          </Content>
          <MainFooter />
          <LangFooter />
        </Layout>
      </Layout>
    </Layout>
  ) : (
    <Navigate to="/login"></Navigate>
  );
};

export default MainLayout;
