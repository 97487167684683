import React from "react";
import { Layout, Result, Button } from "antd";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
const { Content } = Layout;
const Error = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  return (
    <Layout className="layout error-layout">
      <Content>
        <div className="site-layout-content error-container">
          <Result
            status="500"
            title={
              location && location.state && location.state.title
                ? location.state.title
                : t("S_OOOPS")
            }
            subTitle={
              location && location.state && location.state.subtitle
                ? location.state.subtitle
                : t("S_SOMETHINGWENTWRONG")
            }
            extra={
              <Link to="/">
                <Button type="primary">{t("S_GOBACKHOME")}</Button>
              </Link>
            }
          />
        </div>
      </Content>
    </Layout>
  );
};

export default Error;
