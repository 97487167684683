import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Menu, Layout, Grid, theme } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  UserOutlined,
  SettingOutlined,
  BankOutlined,
  SecurityScanOutlined,
  EnvironmentOutlined,
  InteractionOutlined,
  GroupOutlined,
  AppstoreOutlined,
  DashboardOutlined,
  ProjectOutlined,
} from "@ant-design/icons";

const { Sider } = Layout;
const { useBreakpoint } = Grid;

const MainSidebar = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const currentPath = location.pathname;
  const { userInfo } = useSelector((state) => state.authInfo);
  const menuItems = [];
  const appAdminMenuItems = [
    {
      key: "AppManagement",
      icon: <SettingOutlined />,
      label: t("S_APPLICATION_MANAGEMENT"),
      children: [
        {
          key: "/admin/users",
          icon: <UserOutlined />,
          label: <NavLink to="/admin/users">{t("S_USERS")}</NavLink>,
        },
        {
          key: "/admin/companies",
          icon: <BankOutlined />,
          label: <NavLink to="/admin/companies">{t("S_COMPANIES")}</NavLink>,
        },
      ],
    },
  ];
  const companyAdminMenuItems = [
    {
      key: "CompanyManagement",
      icon: <SettingOutlined />,
      label: t("S_COMPANY_MANAGEMENT"),
      children: [
        {
          key: "CompanyInfo",
          icon: <SecurityScanOutlined />,
          label: (
            <NavLink to={`/companyadmin/info`}>
              {t("S_COMPANY_INFORMATION")}
            </NavLink>
          ),
        },
        {
          key: "CompanyUsers",
          icon: <UserOutlined />,
          label: <NavLink to={`/company/admin/users`}>{t("S_USERS")}</NavLink>,
        },
        {
          key: "Locations",
          icon: <EnvironmentOutlined />,
          label: (
            <NavLink to={`/company/admin/locations`}>
              {t("S_LOCATIONS")}
            </NavLink>
          ),
        },
        {
          key: "Statuses",
          icon: <ProjectOutlined />,
          label: <NavLink to={`/company/admin/lists`}>{t("S_LISTS")}</NavLink>,
        },
      ],
    },
  ];
  const userMenuItems = [
    {
      key: "Dashboard",
      icon: <DashboardOutlined />,
      label: <NavLink to={`/app/dashboard`}>{t("S_DASHBOARD")}</NavLink>,
    },
    {
      key: "Inventory",
      icon: <GroupOutlined />,
      label: <NavLink to={`/app/inventory`}>{t("S_INVENTORY")}</NavLink>,
    },
    {
      key: "Stocks",
      icon: <AppstoreOutlined />,
      label: <NavLink to={`/app/stocks`}>{t("S_STOCKS")}</NavLink>,
    },
    {
      key: "StockMovements",
      icon: <InteractionOutlined />,
      label: (
        <NavLink to={`/app/stocks/movements`}>{t("S_STOCK_MOVEMENTS")}</NavLink>
      ),
    },
  ];

  if (userInfo && userInfo.roles) {
    if (userInfo.roles.includes("APPLICATION_ADMIN")) {
      menuItems.push(...appAdminMenuItems);
    }
    if (userInfo.roles.includes("COMPANY_ADMIN")) {
      menuItems.push(...companyAdminMenuItems);
      menuItems.push(...userMenuItems);
    } else {
      menuItems.push(...userMenuItems);
    }
  }

  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const [collapsed, setCollapsed] = useState(false);
  let onHideShowClick = () => {
    setCollapsed(!collapsed);
  };
  const screens = useBreakpoint();
  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      collapsedWidth={screens.xs ? 0 : 80}
      defaultCollapsed={false}
      style={
        screens.xs
          ? {
              background: colorBgContainer,
              position: "absolute",
              zIndex: 100000,
              height: "calc(100vh - 64px)",
              width: "200",
            }
          : { background: colorBgContainer, width: "200" }
      }
    >
      {screens.xs ? (
        <span
          className="ant-layout-sider-zero-width-trigger ant-layout-sider-zero-width-trigger-left"
          onClick={onHideShowClick}
        >
          <span role="img" aria-label="bars" className="anticon anticon-bars">
            <svg
              viewBox="0 0 1024 1024"
              focusable="false"
              data-icon="bars"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M912 192H328c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 284H328c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 284H328c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM104 228a56 56 0 10112 0 56 56 0 10-112 0zm0 284a56 56 0 10112 0 56 56 0 10-112 0zm0 284a56 56 0 10112 0 56 56 0 10-112 0z"></path>
            </svg>
          </span>
        </span>
      ) : (
        ""
      )}
      <Menu
        mode="inline"
        style={{ height: "100%", borderRight: 0 }}
        defaultOpenKeys={["AppManagement", "CompanyManagement"]}
        defaultSelectedKeys={[currentPath]}
        items={menuItems}
      />
    </Sider>
  );
};

export default MainSidebar;
