import React from "react";
import { Button, Result, Row } from "antd";
import { useTranslation } from "react-i18next";

import Link from "antd/es/typography/Link";

const RegisterSuccess = () => {
  const { t } = useTranslation();

  return (
    <div style={{ margin: 0, padding: 0 }}>
      <div style={{ margin: "auto" }}>
        <Row
          type="flex"
          justify="center"
          align="middle"
          style={{ minHeight: "calc(100vh - 182px)" }}
        >
          <Result
            title={t("S_REGISTER_SUCCESS")}
            subTitle={t("S_REGISTER_SUCESS_SUBTEXT")}
            extra={
              <Link to="/">
                <Button type="primary" key="console">
                  {t("S_RETURN_HOME")}
                </Button>
              </Link>
            }
          />
        </Row>
      </div>
    </div>
  );
};

export default RegisterSuccess;
