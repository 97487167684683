import React, { useState } from "react";
import validator from "validator";

import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Typography,
  Image,
  Divider,
  message,
} from "antd";
import { LockOutlined } from "@ant-design/icons";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import logoV64 from "../../resources/images/logo-64.png";

import { useChangePasswordMutation } from "../../store/slices/authSlice";

const { Paragraph } = Typography;

const ChangePassword = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [passwordconfirm, setPasswordConfirm] = useState("");

  const [changePasswordInfo, { isLoading }] = useChangePasswordMutation();

  const { forgotPasswordToken } = useParams();

  const onFinish = async (e) => {
    try {
      await changePasswordInfo({
        password,
        passwordconfirm,
        forgotPasswordToken,
      }).unwrap();
      navigate("/cpsuccess");
    } catch (err) {
      messageApi.open({
        type: "error",
        content: err?.data?.message || err.error,
      });
    }
  };

  const onFinishFailed = (e) => {};

  return (
    <div style={{ margin: 0, padding: 0 }}>
      {contextHolder}
      <div style={{ margin: "auto" }}>
        <Row
          type="flex"
          justify="center"
          align="middle"
          style={{ minHeight: "calc(100vh - 182px)" }}
        >
          <Col>
            <Row justify="center">
              <Image width={64} src={logoV64} preview={false} />
            </Row>
            <Row className="auth-container-body" style={{ marginTop: 20 }}>
              <Form
                style={{ minWidth: "210px" }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                disabled={isLoading}
                layout="vertical"
              >
                <Form.Item justify="center" align="middle">
                  <Paragraph style={{ textAlign: "center" }}>
                    {t("S_CHANGE_PASSWORD_TEXT")}
                  </Paragraph>
                </Form.Item>
                <Form.Item
                  name="password"
                  label={t("S_PASSWORD")}
                  rules={[
                    {
                      required: true,
                      message: t("EM_PASSWORD_REQUIRED"),
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          !value ||
                          validator.isStrongPassword(value, {
                            minLength: 6,
                            minLowercase: 1,
                            minUppercase: 1,
                            minNumbers: 1,
                            minSymbols: 1,
                          })
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error(t("EM_PASSWORD_WEAK")));
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    size="large"
                    placeholder={t("S_PASSWORD")}
                    prefix={<LockOutlined />}
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                  />
                </Form.Item>
                <Form.Item
                  name="passwordconfirm"
                  label={t("S_PASSWORDCONFIRM")}
                  rules={[
                    {
                      required: true,
                      message: t("EM_PASSWORDCONFIRM_REQUIRED"),
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(t("EM_PASSWORD_UNMATCH"))
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    size="large"
                    placeholder={t("S_PASSWORDCONFIRM")}
                    prefix={<LockOutlined />}
                    onChange={(e) => setPasswordConfirm(e.target.value)}
                    value={passwordconfirm}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    loading={isLoading}
                  >
                    {t("S_CHANGE_PASSWORD")}
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Divider />
                </Form.Item>
                <Form.Item style={{ textAlign: "center" }}>
                  <NavLink to="/login">{t("S_ALREADY_HAVE_PASSWORD")}</NavLink>
                </Form.Item>
              </Form>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ChangePassword;
