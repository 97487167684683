import React, { createContext, useContext } from "react";
import { message } from "antd";

const Notifier = createContext();

export const useNotification = () => {
  return useContext(Notifier);
};

export const NotificationProvider = ({ children }) => {
  const openMessage = (type, content) => {
    message[type](content);
  };

  return (
    <Notifier.Provider value={{ openMessage }}>{children}</Notifier.Provider>
  );
};
