import React, { useState } from "react";
import {
  Button,
  Breadcrumb,
  Typography,
  Space,
  Input,
  Select,
  Form,
  Divider,
  Tabs,
  Row,
  Col,
} from "antd";
import { useTranslation } from "react-i18next";
import {
  SaveFilled,
  ReloadOutlined,
  ArrowLeftOutlined,
  CloseOutlined,
  MailOutlined,
  UserOutlined,
  PhoneOutlined,
  TeamOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { useNavigate, NavLink } from "react-router-dom";
import { useNotification } from "../../../../components/Notifier";
import { useCreateCompanyAuthMutation } from "../../../../store/slices/companyAuthSlice";

const { Title } = Typography;
const { Option } = Select;

const CompanyAdminUsersAdd = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { openMessage } = useNotification();
  const [createAuthForm] = Form.useForm();
  const [createAuth, { isLoading: createAuthIsLoading }] =
    useCreateCompanyAuthMutation();
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [usersurname, setUsersurname] = useState("");
  const [phone, setPhone] = useState("");
  const [authStatus, setAuthStatus] = useState("ACTIVE");
  const [roles, setRoles] = useState([]); // State for roles

  const onCreateAuthFinish = async (e) => {
    try {
      await createAuth({
        email,
        username,
        usersurname,
        phone,
        roles,
        status: authStatus,
      }).unwrap();
      openMessage("success", t("M_USER_CREATED"));
      navigate("/company/admin/users");
    } catch (err) {
      openMessage(
        "error",
        t(err?.data?.message || err.error || "S_UPDATE_FAILED")
      );
    }
  };

  const onCreateAuthFinishFailed = (e) => {};

  // Function to reload the page
  const handleReload = () => {
    window.location.reload();
  };

  // Function to navigate back to the auths page
  const handleClose = () => {
    navigate("/company/admin/users");
  };

  return (
    <div className="admin-page">
      <Row align="middle">
        <Col span={24}>
          <Breadcrumb style={{ marginBottom: "16px" }}>
            <Breadcrumb.Item>
              <NavLink to="/company/admin/users">{t("S_USERS")}</NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{t("S_ADD_USER")}</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row align="middle">
        <Col span={12}>
          <Title level={4}>
            <NavLink
              to="/company/admin/users"
              style={{
                marginRight: "8px",
                color: "#000000",
              }}
            >
              <ArrowLeftOutlined />
            </NavLink>
            {t("S_ADD_USER")}
          </Title>
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <div className="add-button-wrapper">
            <Space>
              <Button
                type="primary"
                icon={<SaveFilled />}
                onClick={() => createAuthForm.submit()}
                loading={createAuthIsLoading}
              >
                {t("S_SAVE")}
              </Button>
              <Button icon={<ReloadOutlined />} onClick={handleReload}></Button>
              <Button icon={<CloseOutlined />} onClick={handleClose}></Button>
            </Space>
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: "16px" }}>
        <Col span={24}>
          <Tabs
            type="card"
            tabPosition="top"
            animated
            items={[
              {
                label: t("S_AUTH_DETAILS"),
                key: "details",
                children: (
                  <div className="form-area">
                    <Divider orientation="left" orientationMargin="0">
                      {t("S_AUTH_DETAILS")}
                    </Divider>
                    <Form
                      disabled={createAuthIsLoading}
                      form={createAuthForm}
                      onFinish={onCreateAuthFinish}
                      onFinishFailed={onCreateAuthFinishFailed}
                      layout="vertical"
                    >
                      <Form.Item
                        label={t("S_EMAIL")}
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: t("M_EMAIL_REQUIRED"),
                          },
                          {
                            type: "email",
                            message: t("M_EMAIL_INVALID"),
                          },
                        ]}
                      >
                        <Input
                          placeholder={t("S_EMAIL")}
                          prefix={<MailOutlined />}
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                        />
                      </Form.Item>
                      <Form.Item
                        label={t("S_NAME")}
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: t("M_NAME_REQUIRED"),
                          },
                        ]}
                      >
                        <Input
                          placeholder={t("S_NAME")}
                          prefix={<UserOutlined />}
                          onChange={(e) => setUsername(e.target.value)}
                          value={username}
                        />
                      </Form.Item>
                      <Form.Item
                        label={t("S_SURNAME")}
                        name="surname"
                        rules={[
                          {
                            required: true,
                            message: t("M_SURNAME_REQUIRED"),
                          },
                        ]}
                      >
                        <Input
                          placeholder={t("S_SURNAME")}
                          prefix={<UserOutlined />}
                          onChange={(e) => setUsersurname(e.target.value)}
                          value={usersurname}
                        />
                      </Form.Item>
                      <Form.Item
                        label={t("S_ROLES")}
                        name="roles"
                        rules={[
                          {
                            required: true,
                            message: t("M_ROLES_REQUIRED"),
                          },
                        ]}
                      >
                        <Select
                          mode="multiple"
                          placeholder={t("S_SELECT_ROLES")}
                          prefixIcon={<TeamOutlined />} // Added prefix icon for roles
                          onChange={(values) => setRoles(values)} // Update roles state
                        >
                          <Option value="COMPANY_ADMIN">
                            {t("COMPANY_ADMIN")}
                          </Option>
                          <Option value="COMPANY_OPERATOR">
                            {t("COMPANY_OPERATOR")}
                          </Option>
                          <Option value="USER">{t("USER")}</Option>
                        </Select>
                      </Form.Item>

                      {/* Add Status Field */}
                      <Form.Item
                        label={t("S_STATUS")}
                        name="status"
                        initialValue={authStatus} // Set default value for status
                        rules={[
                          {
                            required: true,
                            message: t("M_STATUS_REQUIRED"),
                          },
                        ]}
                      >
                        <Select
                          onChange={(value) => setAuthStatus(value)} // Update status state
                          value={authStatus}
                          prefixIcon={<CheckCircleOutlined />} // Added prefix icon for status
                        >
                          <Option value="ACTIVE">{t("ACTIVE")}</Option>
                          <Option value="DEACTIVE">{t("DEACTIVE")}</Option>
                        </Select>
                      </Form.Item>

                      <Form.Item
                        label={t("S_PHONE")}
                        name="phone"
                        rules={[
                          {
                            required: true,
                            message: t("M_PHONE_REQUIRED"),
                          },
                          {
                            pattern: /^[0-9]+$/,
                            message: t("M_PHONE_NUMERIC"),
                          },
                        ]}
                      >
                        <Input
                          placeholder={t("S_PHONE")}
                          prefix={<PhoneOutlined />}
                          onChange={(e) => setPhone(e.target.value)}
                          value={phone}
                        />
                      </Form.Item>
                    </Form>
                  </div>
                ),
              },
            ]}
          />
        </Col>
      </Row>
    </div>
  );
};

export default CompanyAdminUsersAdd;
