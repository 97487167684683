import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";
import { BASE_URL, API_PATH_VERSION } from "../constants/constants";

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL + API_PATH_VERSION,
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {},
});

export const apiSlice = createApi({
  baseQuery,
  tagTypes: ["Auth", "Company"],
  endpoints: (builder) => ({}),
});
