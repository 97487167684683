import React, { useState } from "react";
import validator from "validator";

import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Typography,
  Image,
  Divider,
  Checkbox,
  message,
  Tooltip,
  Space,
} from "antd";
import {
  LockOutlined,
  MailOutlined,
  UserOutlined,
  PhoneOutlined,
  BankOutlined,
  LinkOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import logoV64 from "../../resources/images/logo-64.png";

import { useRegisterMutation } from "../../store/slices/authSlice";

const { Paragraph } = Typography;

const Register = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [register, { isLoading }] = useRegisterMutation();

  const [current, setCurrent] = useState(0);
  const prev = () => {
    setCurrent(current - 1);
  };
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordconfirm, setPasswordConfirm] = useState("");
  const [username, setUserName] = useState("");
  const [usersurname, setUserSurname] = useState("");
  const [phone, setPhone] = useState("");
  const [companyname, setCompanyName] = useState("");
  const [companydomainname, setCompanyDomainName] = useState("");

  const onFinishAuth = async (e) => {
    setCurrent(current + 1);
  };

  const onFinishFailedAuth = (e) => {};

  const onFinishUser = async (e) => {
    setCurrent(current + 1);
  };

  const onFinishFailedUser = (e) => {};

  const onFinishCompany = async (e) => {
    try {
      await register({
        email,
        password,
        passwordconfirm,
        username,
        usersurname,
        phone,
        companyname,
        companydomainname,
      }).unwrap();
      navigate("/registersuccess");
    } catch (err) {
      messageApi.open({
        type: "error",
        content: err?.data?.message || err.error,
      });
    }
  };

  const onFinishFailedCompany = (e) => {};

  return (
    <div style={{ margin: 0, padding: 0 }}>
      {contextHolder}
      <div style={{ margin: "auto" }}>
        <Row
          type="flex"
          justify="center"
          align="middle"
          style={{ minHeight: "calc(100vh - 182px)" }}
        >
          <Col>
            <Row justify="center" style={{ marginTop: "32px" }}>
              <Image width={64} src={logoV64} preview={false} />
            </Row>
            <Row className="auth-container-body" style={{ marginTop: 20 }}>
              {current === 0 ? (
                <Form
                  style={{ minWidth: "210px", minHeight: "575px" }}
                  onFinish={onFinishAuth}
                  onFinishFailed={onFinishFailedAuth}
                  layout="vertical"
                  disabled={isLoading}
                >
                  <Form.Item justify="center" align="middle">
                    <Paragraph style={{ textAlign: "center" }}>
                      {t("S_REGISTER_AUTH_TEXT")}
                    </Paragraph>
                  </Form.Item>
                  <Form.Item
                    name="email"
                    label={t("S_EMAIL")}
                    rules={[
                      {
                        required: true,
                        message: t("EM_EMAIL_REQUIRED"),
                      },
                      {
                        type: "email",
                        message: t("EM_EMAIL_INVALID"),
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder={t("S_EMAIL")}
                      prefix={<MailOutlined />}
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    label={t("S_PASSWORD")}
                    rules={[
                      {
                        required: true,
                        message: t("EM_PASSWORD_REQUIRED"),
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            !value ||
                            validator.isStrongPassword(value, {
                              minLength: 6,
                              minLowercase: 1,
                              minUppercase: 1,
                              minNumbers: 1,
                              minSymbols: 1,
                            })
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(t("EM_PASSWORD_WEAK"))
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      size="large"
                      placeholder={t("S_PASSWORD")}
                      prefix={<LockOutlined />}
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                    />
                  </Form.Item>
                  <Form.Item
                    name="passwordconfirm"
                    label={t("S_PASSWORDCONFIRM")}
                    rules={[
                      {
                        required: true,
                        message: t("EM_PASSWORDCONFIRM_REQUIRED"),
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(t("EM_PASSWORD_UNMATCH"))
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      size="large"
                      placeholder={t("S_PASSWORDCONFIRM")}
                      prefix={<LockOutlined />}
                      onChange={(e) => setPasswordConfirm(e.target.value)}
                      value={passwordconfirm}
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      block
                      loading={isLoading}
                    >
                      {t("S_NEXT")}
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Divider />
                  </Form.Item>
                  <Form.Item style={{ textAlign: "center" }}>
                    <NavLink to="/login">{t("S_ALREADY_REGISTERED")}</NavLink>
                  </Form.Item>
                </Form>
              ) : current === 1 ? (
                <Form
                  style={{ minWidth: "210px", minHeight: "575px" }}
                  onFinish={onFinishUser}
                  onFinishFailed={onFinishFailedUser}
                  layout="vertical"
                  disabled={false}
                >
                  <Form.Item justify="center" align="middle">
                    <Paragraph style={{ textAlign: "center" }}>
                      {t("S_REGISTER_USER_TEXT")}
                    </Paragraph>
                  </Form.Item>
                  <Form.Item
                    name="name"
                    label={t("S_NAME")}
                    rules={[
                      {
                        required: true,
                        message: t("EM_NAME_REQUIRED"),
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder={t("S_NAME")}
                      prefix={<UserOutlined />}
                      onChange={(e) => setUserName(e.target.value)}
                      value={username}
                    />
                  </Form.Item>
                  <Form.Item
                    name="surname"
                    label={t("S_SURNAME")}
                    rules={[
                      {
                        required: true,
                        message: t("EM_SURNAME_REQUIRED"),
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder={t("S_SURNAME")}
                      prefix={<UserOutlined />}
                      onChange={(e) => setUserSurname(e.target.value)}
                      value={usersurname}
                    />
                  </Form.Item>
                  <Form.Item
                    name="phone"
                    label={t("S_PHONE")}
                    rules={[
                      {
                        required: true,
                        message: t("EM_PHONE_REQUIRED"),
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder={t("S_PHONE")}
                      prefix={<PhoneOutlined />}
                      onChange={(e) => setPhone(e.target.value)}
                      value={phone}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Space className="next-prev-buttons">
                      <Button block loading={false} onClick={() => prev()}>
                        {t("S_PREVIOUS")}
                      </Button>
                      <Button
                        type="primary"
                        htmlType="submit"
                        block
                        loading={isLoading}
                      >
                        {t("S_NEXT")}
                      </Button>
                    </Space>
                  </Form.Item>
                </Form>
              ) : current === 2 ? (
                <Form
                  style={{ minWidth: "210px", minHeight: "575px" }}
                  onFinish={onFinishCompany}
                  onFinishFailed={onFinishFailedCompany}
                  layout="vertical"
                  disabled={false}
                >
                  <Form.Item justify="center" align="middle">
                    <Paragraph style={{ textAlign: "center" }}>
                      {t("S_REGISTER_COMPANY_TEXT")}
                    </Paragraph>
                  </Form.Item>
                  <Form.Item
                    name="companyname"
                    label={t("S_COMPANY_NAME")}
                    rules={[
                      {
                        required: true,
                        message: t("EM_COMPANY_NAME_REQUIRED"),
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder={t("S_COMPANY_NAME")}
                      prefix={<BankOutlined />}
                      onChange={(e) => setCompanyName(e.target.value)}
                      value={companyname}
                    />
                  </Form.Item>
                  <Form.Item
                    name="companydomainname"
                    label={t("S_COMPANY_DOMAIN_NAME")}
                    rules={[
                      {
                        required: true,
                        message: t("EM_COMPANY_DOMAIN_NAME_REQUIRED"),
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            !value ||
                            (validator.isAlpha(value) &&
                              validator.isLowercase(value))
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(t("EM_COMPANY_DOMAIN_NAME_INAPPROPRIATE"))
                          );
                        },
                      }),
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder={t("S_COMPANY_DOMAIN_NAME")}
                      prefix={<LinkOutlined />}
                      suffix={
                        <Tooltip title={t("S_COMPANY_DOMAIN_NAME_INFORMATION")}>
                          <InfoCircleOutlined />
                        </Tooltip>
                      }
                      onChange={(e) => setCompanyDomainName(e.target.value)}
                      value={companydomainname}
                    />
                  </Form.Item>
                  <Form.Item
                    name="agreement"
                    valuePropName="checked"
                    rules={[
                      {
                        validator: (_, value) =>
                          value
                            ? Promise.resolve()
                            : Promise.reject(
                                new Error(t("EM_AGREEMENT_REQUIRED"))
                              ),
                      },
                    ]}
                  >
                    <Checkbox>
                      {t("S_I_ACCEPT")}{" "}
                      <a href="/agreement">{t("S_AGREEMENT")}</a>
                    </Checkbox>
                  </Form.Item>
                  <Form.Item>
                    <Space className="next-prev-buttons">
                      <Button block loading={false} onClick={() => prev()}>
                        {t("S_PREVIOUS")}
                      </Button>
                      <Button
                        type="primary"
                        htmlType="submit"
                        block
                        loading={isLoading}
                      >
                        {t("S_REGISTER")}
                      </Button>
                    </Space>
                  </Form.Item>
                </Form>
              ) : (
                ""
              )}
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Register;
