import React, { useState } from "react";

import { useNavigate, NavLink } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Typography,
  Image,
  Divider,
  message,
} from "antd";
import { MailOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import logoV64 from "../../resources/images/logo-64.png";

import { useForgotPasswordMutation } from "../../store/slices/authSlice";

const { Paragraph } = Typography;

const ForgotPassword = () => {
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const [email, setEmail] = useState("");

  const navigate = useNavigate();

  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();

  const onFinish = async (e) => {
    try {
      await forgotPassword({ email }).unwrap();
      navigate("/fpsuccess");
    } catch (err) {
      messageApi.open({
        type: "error",
        content: err?.data?.message || err.error,
      });
    }
  };

  const onFinishFailed = async (e) => {};

  return (
    <div style={{ margin: 0, padding: 0 }}>
      {contextHolder}
      <div style={{ margin: "auto" }}>
        <Row
          type="flex"
          justify="center"
          align="middle"
          style={{ minHeight: "calc(100vh - 182px)" }}
        >
          <Col>
            <Row justify="center">
              <Image width={64} src={logoV64} preview={false} />
            </Row>
            <Row className="auth-container-body" style={{ marginTop: 20 }}>
              <Form
                style={{ minWidth: "210px" }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                disabled={isLoading}
              >
                <Form.Item justify="center" align="middle">
                  <Paragraph style={{ textAlign: "center" }}>
                    {t("S_FORGOT_PASSWORD_TEXT")}
                  </Paragraph>
                </Form.Item>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: t("EM_EMAIL_REQUIRED"),
                    },
                    {
                      type: "email",
                      message: t("EM_EMAIL_INVALID"),
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={t("S_EMAIL")}
                    prefix={<MailOutlined />}
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    loading={isLoading}
                  >
                    {t("S_RESET_PASSWORD")}
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Divider />
                </Form.Item>
                <Form.Item style={{ textAlign: "center" }}>
                  <NavLink to="/login">{t("S_REMEMBERED_PASSWORD")}</NavLink>
                </Form.Item>
              </Form>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ForgotPassword;
