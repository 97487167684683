import React from "react";

import { Layout } from "antd";
import { useTranslation } from "react-i18next";

const { Footer } = Layout;

const MainFooter = () => {
  const { t } = useTranslation();
  return (
    <Footer style={{ textAlign: "center" }}>{t("S_MAIN_FOOTER_TEXT")}</Footer>
  );
};

export default MainFooter;
