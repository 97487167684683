import { useEffect } from "react";

import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Result, Row } from "antd";
import { useRegisterCompleteMutation } from "../../store/slices/authSlice";
import { useTranslation } from "react-i18next";
import Loader from "../../components/Loader";

const RegisterComplete = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { registerToken } = useParams();

  const [registerCompleteInfo, { isLoading }] = useRegisterCompleteMutation();

  useEffect(() => {
    const fetchRegisterCompleteInfo = async () => {
      try {
        await registerCompleteInfo(registerToken).unwrap();
      } catch (error) {
        navigate("/error");
      }
    };

    fetchRegisterCompleteInfo();
  }, [navigate, registerCompleteInfo, registerToken]);

  return (
    <div style={{ margin: 0, padding: 0 }}>
      <div style={{ margin: "auto" }}>
        <Row
          type="flex"
          justify="center"
          align="middle"
          style={{ minHeight: "calc(100vh - 182px)" }}
        >
          {isLoading ? (
            <Loader></Loader>
          ) : (
            <Result
              status="success"
              title={t("S_REGISTER_COMPLETE")}
              subTitle={t("S_CLICK_BUTTON_BELOW")}
              extra={
                <Link to="/login">
                  <Button type="primary" key="console">
                    {t("S_LOGIN")}
                  </Button>
                </Link>
              }
            />
          )}
        </Row>
      </div>
    </div>
  );
};

export default RegisterComplete;
