import React, { Fragment } from "react";

import { Row, Col, Space, Button, Layout, Dropdown } from "antd";

import { FormOutlined, LoginOutlined, MenuOutlined } from "@ant-design/icons";
import { Link, NavLink } from "react-router-dom";

import { useTranslation } from "react-i18next";

const { Header } = Layout;

const PublicHeader = () => {
  const { t } = useTranslation();

  return (
    <Header className="header">
      <div className="logo" />
      <Space size="large" className="public-menu">
        <NavLink to="/">{t("S_HOME")}</NavLink>
        <NavLink>{t("S_FEATURES")}</NavLink>
        <NavLink>{t("S_PLANS")}</NavLink>
        <NavLink>{t("S_SSS")}</NavLink>
      </Space>
      <Fragment>
        <Row justify="end">
          <Col span={24} xs={0} sm={24} md={24} lg={24} xl={24} align="right">
            <Space style={{ marginRight: "0px" }}>
              <Link to="/register">
                <Button type="primary" ghost icon={<FormOutlined />}>
                  {t("S_REGISTER")}
                </Button>
              </Link>
              <Link to="/login">
                <Button type="primary" icon={<LoginOutlined />}>
                  {t("S_LOGIN")}
                </Button>
              </Link>
            </Space>
          </Col>
          <Col span={24} xs={24} sm={0} md={0} lg={0} xl={0} align="right">
            <Dropdown
              menu={{
                items: [
                  {
                    label: (
                      <Link to="/register">
                        <Space>
                          <FormOutlined />
                          {t("S_REGISTER")}
                        </Space>
                      </Link>
                    ),
                    key: "register",
                  },
                  {
                    label: (
                      <Link to="/login">
                        <Space>
                          <LoginOutlined />
                          {t("S_LOGIN")}
                        </Space>
                      </Link>
                    ),
                    key: "login",
                  },
                ],
              }}
            >
              <Button type="primary">
                <MenuOutlined />
              </Button>
            </Dropdown>
          </Col>
        </Row>
      </Fragment>
    </Header>
  );
};

export default PublicHeader;
