import React from "react";
import { Outlet } from "react-router-dom";
import { Layout } from "antd";

import PublicHeader from "../components/PublicHeader";
import LangFooter from "../components/LangFooter";

const { Content } = Layout;

const AuthLayout = () => {
  return (
    <Layout className="layout">
      <PublicHeader />
      <Content>
        <div className="site-layout-content auth-container">
          <Outlet />
        </div>
      </Content>
      <LangFooter></LangFooter>
    </Layout>
  );
};

export default AuthLayout;
