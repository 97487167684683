import React from "react";
import { Button, Result, Row } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ChangePasswordSuccess = () => {
  const { t } = useTranslation();

  return (
    <div style={{ margin: 0, padding: 0 }}>
      <div style={{ margin: "auto" }}>
        <Row
          type="flex"
          justify="center"
          align="middle"
          style={{ minHeight: "calc(100vh - 182px)" }}
        >
          <Result
            status="success"
            title={t("S_CHANGE_PASSWORD_SUCCESS")}
            subTitle={t("S_CLICK_BUTTON_BELOW")}
            extra={
              <Link to="/login">
                <Button type="primary" key="console">
                  {t("S_LOGIN")}
                </Button>
              </Link>
            }
          />
        </Row>
      </div>
    </div>
  );
};

export default ChangePasswordSuccess;
